import React, { useEffect } from 'react'
import T from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Form, Formik } from 'formik'

import {
  Button,
  Container,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material'
import useFetchRewardingTransactionsSummary from '../../../hooks/useFetchRewardingTransactionsSummary'

import request from '../../../lib/request'
import useSnackbar from '../Snackbar/useSnackbar'

import TextField from '../../atoms/TextField'
import { RewardingTransactionSchema } from './validators'

export default function RewardingTransactionsSummary({
  companyRewardingTypeId,
  refetchCompanyRewardingType,
}) {
  const { t, i18n } = useTranslation()

  const { showSnackbarMessage } = useSnackbar()

  const {
    fetchedObject: transactionsSummary,
    isFetching: isFetchingTransactionsSummary,
    fetchError: fetchTransactionsSummaryError,
  } = useFetchRewardingTransactionsSummary(companyRewardingTypeId)

  useEffect(() => {
    if (fetchTransactionsSummaryError) {
      showSnackbarMessage({
        open: true,
        severity: 'error',
        message: `${t(
          'common.unexpectedError'
        )} ${fetchTransactionsSummaryError}`,
      })
    }
  }, [fetchTransactionsSummaryError, showSnackbarMessage, t])

  async function handleSubmit(values, actions) {
    try {
      const data = {
        depositAmount: Number(values.depositAmount),
        depositDescription: values.depositDescription,
      }

      await request.post(
        `/admin/rewarding/company/${companyRewardingTypeId}/deposit`,
        data
      )
      actions.setSubmitting(false)
      actions.resetForm()
      await refetchCompanyRewardingType()

      showSnackbarMessage({ open: true, severity: 'success' })
    } catch (e) {
      actions.setSubmitting(false)
      showSnackbarMessage({
        open: true,
        severity: 'error',
        message: e.message,
      })
    }
  }

  const rows = [
    {
      label: t('rewardingPage.transactionsSummary.deposit'),
      amount: transactionsSummary?.deposit || 0,
    },
    {
      label: t('rewardingPage.transactionsSummary.commitment'),
      amount: transactionsSummary?.commitment || 0,
    },
    {
      label: t('rewardingPage.transactionsSummary.withdrawal'),
      amount: transactionsSummary?.withdrawal || 0,
    },
    {
      label: t('rewardingPage.transactionsSummary.balance'),
      amount: transactionsSummary?.balance || 0,
    },
  ]

  return (
    !isFetchingTransactionsSummary && (
      <>
        <TableContainer
          component={Paper}
          sx={{ width: '300px', margin: '20px 0' }}
        >
          <Table size="small">
            <TableBody>
              {rows.map((row, index) => {
                const style =
                  index === rows.length - 1 ? { fontWeight: 'bold' } : {}

                return (
                  <TableRow key={row.label}>
                    <TableCell component="th" scope="row" style={style}>
                      {row.label}
                    </TableCell>
                    <TableCell align="right" style={style}>
                      {Intl.NumberFormat(i18n.language, {
                        style: 'currency',
                        currency: 'EUR',
                      }).format(row.amount || 0)}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Typography variant="h6">
          {t('rewardingPage.transactionsSummary.newDeposit.title')}
        </Typography>

        <Formik
          validationSchema={RewardingTransactionSchema(t)}
          initialValues={{
            depositAmount: '',
            depositDescription: '',
          }}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({ isValid, dirty, isSubmitting }) => (
            <Form>
              <TextField
                margin="normal"
                inputProps={{
                  autoComplete: 'off',
                }}
                size="small"
                sx={{ width: 200 }}
                label={t('rewardingPage.transactionsSummary.newDeposit.amount')}
                InputLabelProps={{ shrink: true }}
                required
                name="depositAmount"
              />

              <TextField
                margin="normal"
                inputProps={{
                  autoComplete: 'off',
                }}
                size="small"
                sx={{ width: 450, marginLeft: '10px' }}
                label={t(
                  'rewardingPage.transactionsSummary.newDeposit.description'
                )}
                InputLabelProps={{ shrink: true }}
                required
                name="depositDescription"
              />

              <Container>
                <Button
                  type="submit"
                  color="primary"
                  disabled={!isValid || !dirty || isSubmitting}
                >
                  {t('rewardingPage.transactionsSummary.newDeposit.submit')}
                </Button>
              </Container>
            </Form>
          )}
        </Formik>
      </>
    )
  )
}

RewardingTransactionsSummary.propTypes = {
  companyRewardingTypeId: T.string.isRequired,
  refetchCompanyRewardingType: T.func.isRequired,
}
