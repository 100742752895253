import React from 'react'
import {
  List,
  ListItem,
  ListItemText,
  Drawer as MuiDrawer,
  ListItemIcon,
  Toolbar,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Link } from 'react-router-dom'
import PersonIcon from '@mui/icons-material/Person'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import GroupIcon from '@mui/icons-material/Group'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import BubbleChartIcon from '@mui/icons-material/BubbleChart'

import SvgIcon from '@mui/material/SvgIcon'

import { useTranslation } from 'react-i18next'
import { ReactComponent as CampaignIcon } from '../../assets/images/campaign.svg'
import { useAuth } from '../../hooks/useAuth'
import useConfig from '../../hooks/useConfig'

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}))

export default function Drawer() {
  const { t } = useTranslation()
  const classes = useStyles()

  const { isAdmin } = useAuth()
  const { messagingEnabled } = useConfig()

  return (
    <MuiDrawer
      className={classes.drawer}
      variant="permanent"
      anchor="left"
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerContainer}>
        <Toolbar />
        <List>
          <Link to="/homepage">
            <ListItem button>
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText>{t('menu.homePage')}</ListItemText>
            </ListItem>
          </Link>
          <Link to="/companies">
            <ListItem button>
              <ListItemIcon>
                <CalendarTodayIcon />
              </ListItemIcon>
              <ListItemText>{t('menu.companies')}</ListItemText>
            </ListItem>
          </Link>
          {isAdmin && (
            <>
              <Link to="/users">
                <ListItem button>
                  <ListItemIcon>
                    <GroupIcon />
                  </ListItemIcon>
                  <ListItemText>{t('menu.users')}</ListItemText>
                </ListItem>
              </Link>
              <Link to="/redemptions">
                <ListItem button>
                  <ListItemIcon>
                    <CreditCardIcon />
                  </ListItemIcon>
                  <ListItemText>{t('menu.redemptions')}</ListItemText>
                </ListItem>
              </Link>
            </>
          )}
          {messagingEnabled && (
            <Link to="/messages">
              <ListItem button>
                <ListItemIcon>
                  <SvgIcon>
                    <CampaignIcon />
                  </SvgIcon>
                </ListItemIcon>
                <ListItemText>{t('menu.messages')}</ListItemText>
              </ListItem>
            </Link>
          )}
          <Link to="/heatMap">
            <ListItem button>
              <ListItemIcon>
                <BubbleChartIcon />
              </ListItemIcon>
              <ListItemText>
                {t('menu.heatMap')} (<strong>beta</strong>)
              </ListItemText>
            </ListItem>
          </Link>
        </List>
      </div>
    </MuiDrawer>
  )
}
