import { DateTimePicker } from '@mui/x-date-pickers'
import { format } from 'date-fns'
import { convertToHTML } from 'draft-convert'
import { EditorState, ContentState, convertFromHTML } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import T from 'prop-types'
import { Formik, Form } from 'formik'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import './index.css'
import { useAuth } from '../../../hooks/useAuth'

import useCompaniesSectors from '../../../hooks/useCompaniesSectors'
import useFetchMessage from '../../../hooks/useFetchMessage'

import request from '../../../lib/request'
import TextField from '../../atoms/TextField'
import Spinner from '../../atoms/Spinner'
import MultiCompanyFilter from '../../molecules/MultiCompanyFilter'
import useSnackbar from '../Snackbar/useSnackbar'
import { useConfirmDialog } from '../../../hooks/useConfirmDialog'
import { MessageSchema } from './validators'

const useStyles = makeStyles((theme) => ({
  multiCompanyRoot: {
    margin: theme.spacing(2, 1),
    width: theme.spacing(60),
  },
  multiCompanyFormControl: { width: theme.spacing(60) },
  multiCompanySelectedBox: {
    marginTop: '20px',
  },
}))

const convertToHtmlWithLinks = (content) => {
  const convert = convertToHTML({
    entityToHTML: (entity, originalText) => {
      if (entity.type === 'LINK') {
        return <a href={entity.data.url}>{originalText}</a>
      }
      return originalText
    },
  })
  return convert(content)
}

export default function MessageForm({ messageId }) {
  const { showSnackbarMessage } = useSnackbar()
  const { user } = useAuth()
  const history = useHistory()
  const classes = useStyles()
  const { t } = useTranslation()
  const { openConfirmDialog } = useConfirmDialog()

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  )
  const companiesSectors = useCompaniesSectors()
  const [companies, setCompanies] = useState([])
  const [companiesById, setCompaniesById] = useState({})
  const [targetCompanyIdsInitialValue, setTargetCompanyIdsInitialValue] =
    useState([])
  const [startDateError, setStartDateError] = React.useState(null)
  const [endDateError, setEndDateError] = React.useState(null)

  const isNew = !messageId
  const [pageMode, setPageMode] = useState(isNew ? 'edit' : 'view')
  const readOnly = pageMode === 'view'

  const {
    fetchedObject: message,
    isFetching: isFetchingMessage,
    fetchError: fetchMessageError,
  } = useFetchMessage(messageId)

  useEffect(() => {
    if (!message || !message.body?.length) {
      return
    }
    const blocksFromHTML = convertFromHTML(message.body)
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    )
    setEditorState(EditorState.createWithContent(state))
  }, [message])

  useEffect(() => {
    const filteredCompanies = companiesSectors
      .filter((companySectors) => !companySectors?.archivedAt)
      .map((companySectors) => ({
        id: companySectors.id,
        name: companySectors.name,
      }))
    setCompanies(filteredCompanies)
    setCompaniesById(
      companiesSectors.reduce((acc, companySectors) => {
        acc[companySectors.id] = companySectors.name
        return acc
      }, {})
    )
    if (filteredCompanies.length === 1) {
      setTargetCompanyIdsInitialValue([filteredCompanies[0].id])
    }
  }, [companiesSectors])

  useEffect(() => {
    if (fetchMessageError) {
      showSnackbarMessage({
        open: true,
        severity: 'error',
        message: `${t('common.unexpectedError')} ${fetchMessageError}`,
      })
    }
  }, [fetchMessageError, showSnackbarMessage, t])

  async function handleSubmit(values, actions) {
    try {
      const data = {
        targetCompanyIds: values.targetCompanyIds,
        channel: values.channel,
        title: values.title,
        body: values.body,
        details: {
          scheduling: {
            frequency: values.frequency,
            startDate: values.startDate,
            endDate: values.endDate,
          },
        },
      }

      if (isNew) {
        const { id } = await request.post(`/admin/messages`, data)
        actions.setSubmitting(false)
        history.replace(`/message/${id}`)
      } else {
        await request.patch(`/admin/messages/${messageId}`, data)
        actions.setSubmitting(false)
        setPageMode('view')
        Object.assign(message, data)
      }

      showSnackbarMessage({ open: true, severity: 'success' })
    } catch (e) {
      showSnackbarMessage({
        open: true,
        severity: 'error',
        message: e.response?.data?.message || e.message,
      })
      actions.setSubmitting(false)
    }
  }

  async function handleSend(e) {
    e.preventDefault()
    openConfirmDialog({
      title: t('messageDetailPage.sendConfirmTitle'),
      description: t('messageDetailPage.sendConfirmDescription'),
      handleClose: null,
      handleConfirm: async () => {
        try {
          await request.post(`/admin/messages/${messageId}/send`)
          showSnackbarMessage({ open: true, severity: 'success' })
          history.replace(`/messages`)
        } catch (e) {
          showSnackbarMessage({
            open: true,
            severity: 'error',
            message: e.message,
          })
        }
      },
    })
  }

  async function handleSendForTest(e) {
    e.preventDefault()
    openConfirmDialog({
      title: t('messageDetailPage.sendForTestConfirmTitle'),
      description: t('messageDetailPage.sendForTestConfirmDescription', {
        email: user.email,
      }),
      handleClose: null,
      handleConfirm: async () => {
        try {
          await request.post(`/admin/messages/${messageId}/sendForTest`)
          showSnackbarMessage({ open: true, severity: 'success' })
        } catch (e) {
          showSnackbarMessage({
            open: true,
            severity: 'error',
            message: e.message,
          })
        }
      },
    })
  }

  async function handleDelete(e) {
    e.preventDefault()
    openConfirmDialog({
      title: t('common.deleteConfirmTitle'),
      description: t('messageDetailPage.deleteConfirmDescription'),
      handleClose: null,
      handleConfirm: async () => {
        try {
          await request.delete(`/admin/messages/${messageId}`)
          showSnackbarMessage({ open: true, severity: 'success' })
          history.replace(`/messages`)
        } catch (e) {
          showSnackbarMessage({
            open: true,
            severity: 'error',
            message: e.message,
          })
        }
      },
    })
  }

  function handleEdit(e) {
    e.preventDefault()
    setPageMode('edit')
  }

  function handleReset() {
    setPageMode(isNew ? 'edit' : 'view')
  }

  return isFetchingMessage ? (
    <Spinner show={true} />
  ) : (
    <Box maxWidth={700} ml={0} mt={1}>
      {!isNew && message?.details?.sentDate && (
        <Typography variant="h6" color="primary">
          {t('messageDetailPage.sentOn')}{' '}
          {new Date(message.details.sentDate).toLocaleString()}
        </Typography>
      )}

      {!isNew &&
        message?.details?.scheduling?.frequency === 'ON_DEMAND' &&
        !message?.details?.sentDate && (
          <Typography variant="h6" color="primary">
            {t('messageDetailPage.readyToSend')}
          </Typography>
        )}

      {!isNew &&
        message?.details?.scheduling?.frequency &&
        message.details.scheduling.frequency !== 'ON_DEMAND' && (
          <Typography variant="h6" color="primary">
            {t(
              `messageDetailPage.scheduled_${message.details.scheduling.frequency}`,
              {
                startDate: format(
                  message.details.scheduling.startDate,
                  "dd/MM/yyyy 'alle' HH:mm"
                ),
                endDate:
                  message.details.scheduling.endDate &&
                  format(
                    message.details.scheduling.endDate,
                    "dd/MM/yyyy 'alle' HH:mm"
                  ),
              }
            )}
          </Typography>
        )}

      <Typography variant="subtitle2">
        {t('messageDetailPage.disclaimer')}
      </Typography>
      <Formik
        validationSchema={MessageSchema(t)}
        initialValues={{
          targetCompanyIds:
            message?.targetCompanyIds || targetCompanyIdsInitialValue,
          channel: message?.channel || '',
          title: message?.title || '',
          body: message?.body || '',
          frequency: message?.details?.scheduling?.frequency || 'ON_DEMAND',
          startDate: message?.details?.scheduling?.startDate
            ? new Date(message.details.scheduling.startDate)
            : null,
          endDate: message?.details?.scheduling?.endDate
            ? new Date(message.details.scheduling.endDate)
            : null,
        }}
        onSubmit={handleSubmit}
        onReset={handleReset}
        enableReinitialize={true}
      >
        {({ isValid, dirty, isSubmitting, values, setFieldValue }) => (
          <Form>
            <MultiCompanyFilter
              companies={companies}
              companyIds={values.targetCompanyIds}
              companiesById={companiesById}
              classes={classes}
              showSelected={true}
              readOnly={!!readOnly}
              onChange={(event) => {
                const { value } = event.target
                // On autofill we get a stringified value.
                const valueArr =
                  typeof value === 'string' ? value.split(',') : value
                return setFieldValue('targetCompanyIds', valueArr)
              }}
            />

            <FormControl style={{ marginTop: '8px' }}>
              <FormLabel>{t('entities.message.channel')}: *</FormLabel>
              <RadioGroup
                row
                aria-label="rides"
                name="channel"
                value={values.channel}
                onChange={(event, newValue) => {
                  setFieldValue('channel', newValue)
                }}
              >
                <FormControlLabel
                  value="EMAIL"
                  control={<Radio />}
                  label={t('entities.message.emailChannel')}
                  disabled={readOnly}
                />
                <FormControlLabel
                  value="PUSH"
                  control={<Radio />}
                  label={t('entities.message.pushChannel')}
                  disabled={readOnly}
                />
              </RadioGroup>
            </FormControl>

            {values.channel === 'PUSH' && (
              <TextField
                fullWidth
                margin="dense"
                required
                inputProps={{
                  autoComplete: 'off',
                  readOnly,
                }}
                label={t('entities.message.message')}
                name="title"
                size="small"
              />
            )}

            {values.channel === 'EMAIL' && (
              <>
                <TextField
                  fullWidth
                  margin="dense"
                  required
                  inputProps={{
                    autoComplete: 'off',
                    readOnly,
                  }}
                  label={t('entities.message.title')}
                  name="title"
                  size="small"
                  style={{ marginBottom: '1rem' }}
                />
                <FormLabel>{t('entities.message.body')}:</FormLabel>
                <Editor
                  editorState={editorState}
                  onEditorStateChange={(newEditorState) => {
                    let html = convertToHtmlWithLinks(
                      newEditorState.getCurrentContent()
                    )
                    setFieldValue('body', html)
                    setEditorState(newEditorState)
                  }}
                  readOnly={readOnly}
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class"
                  toolbarClassName="toolbar-class"
                  toolbar={{
                    options: ['inline', 'link', 'remove', 'history'],
                    inline: {
                      options: ['bold', 'italic', 'underline'],
                    },
                  }}
                />
              </>
            )}

            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              margin="1rem 0"
            >
              <FormControl variant="outlined" margin="dense">
                <InputLabel id="frequency-label">
                  {t('entities.message.frequency')}
                </InputLabel>
                <Select
                  labelId="frequency-label"
                  id="frequency"
                  name="frequency"
                  value={values.frequency}
                  onChange={(e) => {
                    return setFieldValue('frequency', e.target.value)
                  }}
                  label={t('entities.message.frequency')}
                  disabled={readOnly}
                >
                  {['ON_DEMAND', 'ONCE', 'DAILY', 'WEEKLY', 'MONTHLY'].map(
                    (frequency) => (
                      <MenuItem value={frequency} key={frequency}>
                        {t(`entities.message.frequencies.${frequency}`)}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>

              {values.frequency !== 'ON_DEMAND' && (
                <>
                  <DateTimePicker
                    label={t('entities.message.startDate')}
                    value={values.startDate}
                    onChange={(newDate) => {
                      return setFieldValue('startDate', newDate)
                    }}
                    onError={(newError) => setStartDateError(newError)}
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    disabled={readOnly}
                    slotProps={{
                      textField: {
                        sx: { marginLeft: '2rem' },
                        helperText:
                          startDateError &&
                          t(
                            `messageDetailPage.startDate_${startDateError}_error`
                          ),
                      },
                    }}
                    minDateTime={pageMode === 'view' ? null : new Date()}
                  />

                  {values.frequency !== 'ONCE' && (
                    <DateTimePicker
                      label={t('entities.message.endDate')}
                      value={values.endDate}
                      onChange={(newDate) => {
                        return setFieldValue('endDate', newDate)
                      }}
                      onError={(newError) => setEndDateError(newError)}
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      disabled={readOnly}
                      slotProps={{
                        textField: {
                          sx: { marginLeft: '2rem' },
                          helperText:
                            endDateError &&
                            t(
                              `messageDetailPage.endDate_${endDateError}_error`
                            ),
                        },
                      }}
                      minDateTime={
                        pageMode === 'view' ? null : values.startDate
                      }
                    />
                  )}
                </>
              )}
            </Box>

            <Container>
              {pageMode === 'view' ? (
                <>
                  <Button
                    onClick={() => history.goBack()}
                    color="secondary"
                    aria-label={t('common.back')}
                  >
                    {t('common.back')}
                  </Button>
                  {!message?.details?.sentDate && (
                    <>
                      <Button
                        onClick={handleEdit}
                        color="primary"
                        aria-label={t('common.edit')}
                      >
                        {t('common.edit')}
                      </Button>
                      {values.channel === 'EMAIL' && (
                        <Button
                          onClick={handleSendForTest}
                          color="primary"
                          aria-label={t('messageDetailPage.sendForTest')}
                        >
                          {t('messageDetailPage.sendForTest')}
                        </Button>
                      )}
                      {values.frequency === 'ON_DEMAND' && (
                        <Button
                          onClick={handleSend}
                          color="primary"
                          aria-label={t('messageDetailPage.send')}
                        >
                          {t('messageDetailPage.send')}
                        </Button>
                      )}
                    </>
                  )}
                  <Button
                    onClick={handleDelete}
                    color="primary"
                    aria-label={t('common.delete')}
                  >
                    {t('common.delete')}
                  </Button>
                </>
              ) : (
                <>
                  {isNew ? (
                    <Button
                      onClick={() => history.goBack()}
                      color="secondary"
                      aria-label={t('common.back')}
                    >
                      {t('common.back')}
                    </Button>
                  ) : (
                    <Button
                      type="reset"
                      color="secondary"
                      aria-label={t('common.cancel')}
                    >
                      {t('common.cancel')}
                    </Button>
                  )}
                  <Button
                    type="submit"
                    disabled={!isValid || !dirty || isSubmitting}
                    color="primary"
                  >
                    {t('common.save')}
                  </Button>
                </>
              )}
            </Container>
          </Form>
        )}
      </Formik>
    </Box>
  )
}

MessageForm.propTypes = {
  messageId: T.string,
}
