import * as Yup from 'yup'

export const emailsCSV = (t) => {
  const invalidEmail = t('common.invalidEmail')

  return Yup.array()
    .transform(function (value, originalValue) {
      if (this.isType(value) && value !== null) {
        return value
      }
      return originalValue ? originalValue.split(',').map((v) => v.trim()) : []
    })
    .of(Yup.string().email(({ value }) => `"${value}" ${invalidEmail} `))
}
