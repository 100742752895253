import { useCallback } from 'react'
import request from '../lib/request'
import useFetch from './useFetch'

export default function useFetchCompanyRewardingType(companyRewardingTypeId) {
  const rewardingTypeFetcher = useCallback(async () => {
    if (!companyRewardingTypeId) {
      return null
    }
    return request.get('/admin/rewarding/company/' + companyRewardingTypeId)
  }, [companyRewardingTypeId])

  return useFetch(rewardingTypeFetcher)
}
