import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material'
import T from 'prop-types'
import React, { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'

export const ConfirmDialogContext = React.createContext(
  'confirm-dialog-context'
)

const initialState = {
  isOpen: false,
  title: null,
  description: null,
  confirmText: null,
  handleClose: null,
  handleConfirm: null,
}

export const ConfirmDialogProvider = ({ children }) => {
  const [state, setState] = useState(initialState)
  const [typedText, setTypedText] = useState('')
  const { t } = useTranslation()

  const openConfirmDialog = ({
    title,
    description,
    confirmText = null,
    handleConfirm,
    handleClose,
  }) => {
    setState({
      isOpen: true,
      title,
      description,
      confirmText,
      handleConfirm,
      handleClose,
    })
    setTypedText('')
  }

  const closeConfirmDialog = () => setState(initialState)

  const confirmHandler = (e) => {
    e.preventDefault()
    closeConfirmDialog()
    state.handleConfirm && state.handleConfirm()
  }

  const closeHandler = (e) => {
    e.preventDefault()
    closeConfirmDialog()
    state.handleClose && state.handleClose()
  }

  return (
    <ConfirmDialogContext.Provider
      value={{ openConfirmDialog, closeConfirmDialog }}
    >
      <div>
        <Dialog
          open={state.isOpen}
          onClose={closeHandler}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{state.title}</DialogTitle>
          <DialogContent>
            <span
              dangerouslySetInnerHTML={{
                __html: state.description,
              }}
            />
            {!!state.confirmText && (
              <TextField
                label={t('confirmDialog.confirmTextLabel', {
                  confirmText: state.confirmText,
                })}
                margin="dense"
                fullWidth
                value={typedText}
                onChange={(e) => setTypedText(e.target.value)}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={closeHandler}>{t('common.cancel')}</Button>
            <Button
              onClick={confirmHandler}
              autoFocus
              disabled={state.confirmText && state.confirmText !== typedText}
            >
              {t('common.confirm')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      {children}
    </ConfirmDialogContext.Provider>
  )
}

export const useConfirmDialog = function () {
  return useContext(ConfirmDialogContext)
}

ConfirmDialogProvider.propTypes = {
  children: T.oneOfType([T.arrayOf(T.node), T.node]).isRequired,
}
