import React from 'react'
import T from 'prop-types'
import { useTranslation } from 'react-i18next'

import {
  BarChart as CBarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Bar,
  Cell,
  Text,
} from 'recharts'

import { Box, Typography } from '@mui/material'
import theme from '../../../theme'

export default function BarChart({ data = [], colors, dataKeys, colorsKey }) {
  const { t } = useTranslation()

  const CustomizedAxisTick = (props) => {
    const { x, y, payload } = props
    return (
      <Text x={x} y={y} width="100" textAnchor="middle" verticalAnchor="start">
        {payload.value}
      </Text>
    )
  }

  CustomizedAxisTick.propTypes = {
    x: T.number,
    y: T.number,
    payload: T.object,
  }

  return (
    <ResponsiveContainer width="100%" height={300}>
      <CBarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        <XAxis
          dataKey="name"
          xAxisId={0}
          interval={0}
          tick={<CustomizedAxisTick />}
          height={35}
        />
        <XAxis dataKey="name" xAxisId={1} hide />
        <YAxis />
        <Tooltip
          content={(tooltipProps) => {
            const { active, payload, label, separator } = tooltipProps
            if (active && payload && payload.length) {
              return (
                <Box
                  sx={{
                    m: 0,
                    p: 1.25,
                    backgroundColor: theme.palette.common.white,
                    border: `1px solid ${theme.palette.divider}`,
                    whiteSpace: 'nowrap',
                  }}
                >
                  <Typography>{label}</Typography>
                  <Box component="ul" sx={{ m: 0, p: 0 }}>
                    {payload.map((item, i) => (
                      <Box
                        component="li"
                        key={i}
                        sx={{
                          display: 'block',
                          py: 0.5,
                          color: item?.payload?.barColors?.[i],
                        }}
                      >
                        <Typography>
                          <span>
                            {t(`dashboard.charts.bars.${item?.dataKey}`)}
                          </span>
                          <span>{separator}</span>
                          <span>{item?.value}</span>
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
              )
            }

            return null
          }}
        />
        <Bar dataKey={dataKeys?.[0]} fill="#8884d8" xAxisId={0}>
          {data.map((entry, index) => (
            <Cell
              key={`cell-a-${index}`}
              fill={
                data?.[index]?.[colorsKey]?.[0] ||
                (colors && colors[index % colors.length]) ||
                '#8884d8'
              }
            />
          ))}
        </Bar>
        <Bar
          dataKey={dataKeys?.[1]}
          fill="#82ca9d"
          xAxisId={1}
          label={{ position: 'top', fill: '#000000' }}
        >
          {data.map((entry, index) => (
            <Cell
              key={`cell-b-${index}`}
              fill={
                data?.[index]?.[colorsKey]?.[1] ||
                (colors &&
                  colors[
                    (index + Math.round(colors.length / 2)) % colors.length
                  ]) ||
                '#82ca9d'
              }
            />
          ))}
        </Bar>
      </CBarChart>
    </ResponsiveContainer>
  )
}

BarChart.propTypes = {
  data: T.array,
  dataKeys: T.array.isRequired,
  colorsKey: T.string,
  colors: T.array,
  percent: T.bool,
}
