import React from 'react'
import { Toolbar, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import RedemptionTable from '../organisms/RedemptionTable'

export default function Redemptions() {
  const { t } = useTranslation()

  return (
    <div className="content-container">
      <Toolbar />
      <Typography variant="h3">{t('redemptionsPage.title')}</Typography>
      <RedemptionTable />
    </div>
  )
}
