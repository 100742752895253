import React, { Suspense } from 'react'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { Amplify } from 'aws-amplify'

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { it } from 'date-fns/locale'

import { AmplifyProvider } from '@aws-amplify/ui-react'
import SnackbarProvider from './components/organisms/Snackbar'

import MainRouter from './MainRouter'

import config from './config'

import theme from './theme'
import amplifyTheme from './amplifyTheme'
import './App.css'
import './i18n'

// Important to configure amplify here
// because the gqlClient above uses it
Amplify.configure(config.Amplify)

function App() {
  return (
    <Suspense fallback="loading">
      <div className="App">
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <AmplifyProvider theme={amplifyTheme}>
              <SnackbarProvider>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={it}
                >
                  <MainRouter />
                </LocalizationProvider>
              </SnackbarProvider>
            </AmplifyProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </div>
    </Suspense>
  )
}

export default App
