import { makeStyles } from '@mui/styles'
import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  Divider,
  FormControl,
  InputLabel,
  List,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Tooltip,
  Typography,
} from '@mui/material'
import T from 'prop-types'
import { Formik, Form } from 'formik'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useConfirmDialog } from '../../../hooks/useConfirmDialog'
import useFetchCompanyRewardingType from '../../../hooks/useFetchCompanyRewardingType'
import useFetchRewardingTypes, {
  rewardingTypeIds,
} from '../../../hooks/useFetchRewardingTypes'
import useFetchSectors from '../../../hooks/useFetchSectors'

import request from '../../../lib/request'
import Spinner from '../../atoms/Spinner'
import TextField from '../../atoms/TextField'
import RewardingTransactionsSummary from '../RewardingTransactionsSummary'
import useSnackbar from '../Snackbar/useSnackbar'
import { useAuth } from '../../../hooks/useAuth'
import { RewardingSchema } from './validators'

const slotProps = {
  popper: {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 20],
        },
      },
    ],
  },
}

const nullOrNumber = (x) => (x ? Number(x) : null)

const useStyles = makeStyles((theme) => ({
  menuHeadquarters: {
    height: 400,
  },
}))

export default function RewardingForm({ companyId, companyRewardingTypeId }) {
  const { showSnackbarMessage } = useSnackbar()
  const history = useHistory()
  const { openConfirmDialog } = useConfirmDialog()
  const { t } = useTranslation()
  const classes = useStyles()

  const { isAdmin } = useAuth()

  const isNew = !companyRewardingTypeId
  const [pageMode, setPageMode] = useState(isNew ? 'edit' : 'view')
  const readOnly = pageMode === 'view'

  const {
    fetchedObject: companyRewardingType,
    isFetching: isFetchingCompanyRewardingType,
    fetchError: fetchCompanyRewardingTypeError,
    refetch: refetchCompanyRewardingType,
  } = useFetchCompanyRewardingType(companyRewardingTypeId)

  const {
    fetchedObject: rewardingTypes,
    isFetching: isFetchingRewardingTypes,
    fetchError: fetchRewardingTypesError,
  } = useFetchRewardingTypes()

  const sectors = useFetchSectors({ companyId })

  useEffect(() => {
    if (fetchCompanyRewardingTypeError || fetchRewardingTypesError) {
      showSnackbarMessage({
        open: true,
        severity: 'error',
        message: `${t('common.unexpectedError')} ${
          fetchCompanyRewardingTypeError || fetchRewardingTypesError
        }`,
      })
    }
  }, [
    fetchCompanyRewardingTypeError,
    fetchRewardingTypesError,
    showSnackbarMessage,
    t,
  ])

  async function handleSubmit(values, actions) {
    try {
      const data = {
        rewardingTypeId: values.rewardingTypeId,
        attrs: {
          sectorIds: values.sectorIds.map((head) => head.id),
        },
      }

      if (
        data.rewardingTypeId === rewardingTypeIds.VOUCHER ||
        data.rewardingTypeId === rewardingTypeIds.EXTERNAL_WITH_REDEMPTION
      ) {
        data.attrs = {
          ...data.attrs,
          creditValueInEuro: values.creditValueInEuro,
          redemptionAmounts: values.redemptionAmounts,
          maxCreditsPerDay: nullOrNumber(values.maxCreditsPerDay),
          maxCreditsPerWeek: nullOrNumber(values.maxCreditsPerWeek),
          maxCreditsPerMonth: nullOrNumber(values.maxCreditsPerMonth),
          rewardingMessage: values.rewardingMessage,
        }
      }

      if (data.rewardingTypeId === rewardingTypeIds.EXTERNAL_WITH_REDEMPTION) {
        data.attrs.companyEmails = values.companyEmails
      }

      if (isNew) {
        // Adjust API endpoint for creation
        await request.post(`/admin/rewarding/company`, {
          ...data,
          companyId,
        })
        actions.setSubmitting(false)
        history.replace(`/company/${companyId}/rewardings`)
      } else {
        // Adjust API endpoint for update
        const { id: newCompanyRewardingTypeId } = await request.patch(
          `/admin/rewarding/company/${companyRewardingTypeId}`,
          data
        )
        actions.setSubmitting(false)
        setPageMode('view')
        if (newCompanyRewardingTypeId !== companyRewardingTypeId) {
          history.replace(
            `/company/${companyId}/rewarding/${newCompanyRewardingTypeId}`
          )
        } else {
          Object.assign(companyRewardingType, data)
        }
      }

      showSnackbarMessage({ open: true, severity: 'success' })
    } catch (e) {
      showSnackbarMessage({
        open: true,
        severity: 'error',
        message: e.message,
      })
      actions.setSubmitting(false)
    }
  }

  async function handleDelete(e) {
    e.preventDefault()
    openConfirmDialog({
      title: t('common.deleteConfirmTitle'),
      description: t('rewardingPage.deleteConfirmDescription'),
      handleClose: null,
      handleConfirm: async () => {
        try {
          await request.delete(
            `/admin/rewarding/company/${companyRewardingTypeId}`
          )
          showSnackbarMessage({ open: true, severity: 'success' })
          history.replace(`/company/${companyId}/rewardings`)
        } catch (e) {
          showSnackbarMessage({
            open: true,
            severity: 'error',
            message: e.message,
          })
        }
      },
    })
  }

  function handleEdit(e) {
    e.preventDefault()
    setPageMode('edit')
  }

  function handleReset() {
    setPageMode('view')
  }

  return isFetchingCompanyRewardingType || isFetchingRewardingTypes ? (
    <Spinner show={true} />
  ) : (
    <Box maxWidth={900} ml={0} mt={2}>
      <Formik
        validationSchema={RewardingSchema(t)}
        initialValues={{
          rewardingTypeId: companyRewardingType?.rewardingTypeId || '',
          creditValueInEuro:
            companyRewardingType?.attrs?.creditValueInEuro || 0.5,
          redemptionAmounts:
            companyRewardingType?.attrs?.redemptionAmounts || '',
          maxCreditsPerDay: companyRewardingType?.attrs?.maxCreditsPerDay || '',
          maxCreditsPerWeek:
            companyRewardingType?.attrs?.maxCreditsPerWeek || '',
          maxCreditsPerMonth:
            companyRewardingType?.attrs?.maxCreditsPerMonth || '',
          companyEmails: companyRewardingType?.attrs?.companyEmails || '',
          rewardingMessage: companyRewardingType?.attrs?.rewardingMessage || '',
          sectorIds: [...(sectors?.fetchedObject || [])].filter(
            (sector) =>
              companyRewardingType?.attrs?.sectorIds?.indexOf(sector.id) > -1
          ),
        }}
        onSubmit={handleSubmit}
        onReset={handleReset}
        enableReinitialize={true}
      >
        {({
          isValid,
          dirty,
          isSubmitting,
          values,
          handleChange,
          setFieldValue,
        }) => {
          return (
            <Form>
              {!isFetchingRewardingTypes && (
                <>
                  <FormControl fullWidth size="small">
                    <InputLabel id="rewardingTypeId-label">
                      {t('entities.rewarding.rewardingType')}
                    </InputLabel>
                    <Select
                      // MenuProps={{ className: classes.menuHeadquarters }}
                      labelId="rewardingTypeId-label"
                      label={t('entities.rewarding.rewardingType')}
                      name="rewardingTypeId"
                      disabled={!!readOnly}
                      value={values.rewardingTypeId}
                      onChange={handleChange}
                    >
                      {rewardingTypes.map((rt) => (
                        <MenuItem key={rt.id} value={rt.id}>
                          {t(`entities.rewardingTypes.${rt.i18nLabel}`)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <Box
                    sx={{
                      width: '100%',
                      marginTop: '20px',
                      marginBottom: '10px',
                    }}
                  >
                    {!readOnly && sectors.fetchedObject ? (
                      <FormControl fullWidth size="small">
                        <InputLabel id="sectorId-label">
                          {t('rewardingPage.sectors')}
                        </InputLabel>
                        <Select
                          MenuProps={{ className: classes.menuHeadquarters }}
                          labelId="sectorId-label"
                          label={'Settori'}
                          name="sectorIds"
                          multiple
                          value={values.sectorIds}
                          renderValue={(selected) => {
                            return selected
                              ? selected.map((head) => head.name).join(', ')
                              : t('common.select')
                          }}
                          disabled={!!readOnly}
                          onChange={({ target }) => {
                            const { value } = target
                            if (value[value.length - 1] === 'all') {
                              setFieldValue(
                                'sectorIds',
                                values.sectorIds.length ===
                                  sectors.fetchedObject.length
                                  ? []
                                  : sectors.fetchedObject
                              )
                              return
                            }
                            setFieldValue('sectorIds', value)
                          }}
                        >
                          <MenuItem value={'all'}>
                            <Checkbox
                              name="all"
                              checked={
                                values.sectorIds.length ===
                                sectors.fetchedObject.length
                              }
                            />
                            <ListItemText primary={t('common.selectAll')} />
                          </MenuItem>
                          {sectors.fetchedObject.map((c) => {
                            return (
                              <MenuItem key={c.id} value={c}>
                                <Checkbox
                                  name="sector"
                                  checked={
                                    !!values.sectorIds?.find(
                                      (sector) => sector.id === c.id
                                    )
                                  }
                                />
                                <ListItemText primary={c.name} />
                              </MenuItem>
                            )
                          })}
                        </Select>
                      </FormControl>
                    ) : (
                      readOnly &&
                      sectors.fetchedObject && (
                        <>
                          <Paper
                            sx={{
                              width: '100%',
                              padding: '0px 8px 0px 8px',
                              position: 'relative',
                              '&:after': {
                                content: '" "',
                                position: 'absolute',
                                bottom: 0,
                                background:
                                  'linear-gradient(to bottom, transparent, white)',
                                height: '50px',
                                width: '100%',
                              },
                            }}
                          >
                            <Typography variant="h6">
                              {t('rewardingPage.sectors')}
                            </Typography>
                            <List
                              className="headertersList"
                              sx={{
                                position: 'relative',
                                overflow: 'auto',
                                maxHeight: 300,
                                paddingTop: 0,
                                paddingBottom: '32px',
                                '& ul': { padding: 0 },
                              }}
                            >
                              {values.sectorIds.map((sector) => (
                                <li key={sector.id}>{sector.name}</li>
                              ))}
                            </List>
                          </Paper>
                        </>
                      )
                    )}

                    <Typography
                      variant="p"
                      sx={{ marginTop: '4px !important', marginLeft: '10px' }}
                    >
                      {t('rewardingPage.help_sectors')}
                    </Typography>
                  </Box>

                  {(values.rewardingTypeId === rewardingTypeIds.VOUCHER ||
                    values.rewardingTypeId ===
                      rewardingTypeIds.EXTERNAL_WITH_REDEMPTION) && (
                    <>
                      <Box sx={{ whiteSpace: 'nowrap' }}>
                        <TextField
                          margin="normal"
                          inputProps={{
                            autoComplete: 'off',
                            readOnly,
                          }}
                          size="small"
                          label={t('entities.rewarding.creditValueInEuro')}
                          InputLabelProps={{ shrink: true }}
                          required
                          name="creditValueInEuro"
                        />

                        <TextField
                          margin="normal"
                          inputProps={{
                            autoComplete: 'off',
                            readOnly,
                          }}
                          size="small"
                          sx={{ width: 300, ml: 1 }}
                          label={t('entities.rewarding.redemptionAmounts')}
                          InputLabelProps={{ shrink: true }}
                          required
                          name="redemptionAmounts"
                        />
                      </Box>

                      <Box sx={{ whiteSpace: 'nowrap' }}>
                        <Tooltip
                          title={t('entities.rewarding.maxCreditsTooltip')}
                          slotProps={slotProps}
                        >
                          <span>
                            <TextField
                              margin="normal"
                              inputProps={{
                                autoComplete: 'off',
                                readOnly,
                              }}
                              size="small"
                              label={t('entities.rewarding.maxCreditsPerDay')}
                              InputLabelProps={{ shrink: true }}
                              name="maxCreditsPerDay"
                            />
                          </span>
                        </Tooltip>

                        <Tooltip
                          title={t('entities.rewarding.maxCreditsTooltip')}
                          slotProps={slotProps}
                        >
                          <span>
                            <TextField
                              margin="normal"
                              inputProps={{
                                autoComplete: 'off',
                                readOnly,
                              }}
                              size="small"
                              sx={{ ml: 1 }}
                              label={t('entities.rewarding.maxCreditsPerWeek')}
                              InputLabelProps={{ shrink: true }}
                              name="maxCreditsPerWeek"
                            />
                          </span>
                        </Tooltip>

                        <Tooltip
                          title={t('entities.rewarding.maxCreditsTooltip')}
                          slotProps={slotProps}
                        >
                          <span>
                            <TextField
                              margin="normal"
                              inputProps={{
                                autoComplete: 'off',
                                readOnly,
                              }}
                              size="small"
                              sx={{ ml: 1 }}
                              label={t('entities.rewarding.maxCreditsPerMonth')}
                              InputLabelProps={{ shrink: true }}
                              name="maxCreditsPerMonth"
                            />
                          </span>
                        </Tooltip>
                      </Box>
                    </>
                  )}

                  {values.rewardingTypeId ===
                    rewardingTypeIds.EXTERNAL_WITH_REDEMPTION && (
                    <Box sx={{ whiteSpace: 'nowrap' }}>
                      <TextField
                        margin="normal"
                        inputProps={{
                          autoComplete: 'off',
                          readOnly,
                        }}
                        size="small"
                        sx={{ width: '100%' }}
                        label={t('entities.rewarding.companyEmails')}
                        InputLabelProps={{ shrink: true }}
                        name="companyEmails"
                      />
                    </Box>
                  )}

                  {(values.rewardingTypeId === rewardingTypeIds.VOUCHER ||
                    values.rewardingTypeId ===
                      rewardingTypeIds.EXTERNAL_WITH_REDEMPTION) && (
                    <Box sx={{ whiteSpace: 'nowrap' }}>
                      <TextField
                        margin="normal"
                        inputProps={{
                          autoComplete: 'off',
                          readOnly,
                        }}
                        size="small"
                        sx={{ width: '100%' }}
                        label={t('entities.rewarding.rewardingMessage')}
                        InputLabelProps={{ shrink: true }}
                        name="rewardingMessage"
                      />
                    </Box>
                  )}
                </>
              )}

              <Container>
                {readOnly ? (
                  <>
                    <Button
                      onClick={() => history.goBack()}
                      color="secondary"
                      aria-label={t('common.back')}
                    >
                      {t('common.back')}
                    </Button>
                    {isAdmin && (
                      <>
                        <Button
                          onClick={handleEdit}
                          color="primary"
                          aria-label={t('common.edit')}
                        >
                          {t('common.edit')}
                        </Button>
                        <Button
                          onClick={handleDelete}
                          color="primary"
                          aria-label={t('common.delete')}
                        >
                          {t('common.delete')}
                        </Button>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {isNew ? (
                      <Button
                        onClick={() => history.goBack()}
                        color="secondary"
                        aria-label={t('common.back')}
                      >
                        {t('common.back')}
                      </Button>
                    ) : (
                      <Button
                        type="reset"
                        color="secondary"
                        aria-label={t('common.cancel')}
                      >
                        {t('common.cancel')}
                      </Button>
                    )}
                    <Button
                      type="submit"
                      disabled={!isValid || !dirty || isSubmitting}
                      color="primary"
                    >
                      {t('common.save')}
                    </Button>
                  </>
                )}
              </Container>
            </Form>
          )
        }}
      </Formik>

      {companyRewardingType?.rewardingTypeId === rewardingTypeIds.VOUCHER && (
        <>
          <Divider sx={{ m: 3 }} />

          <Card>
            <CardContent>
              <Typography variant="h5">
                {t('rewardingPage.transactionsSummary.title')}
              </Typography>
              <RewardingTransactionsSummary
                companyRewardingTypeId={companyRewardingTypeId}
                refetchCompanyRewardingType={refetchCompanyRewardingType}
              />
            </CardContent>
          </Card>
        </>
      )}
    </Box>
  )
}

RewardingForm.propTypes = {
  companyId: T.string.isRequired,
  companyRewardingTypeId: T.string,
}
