import * as Yup from 'yup'

const domainRexExp =
  /^@(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/

Yup.addMethod(Yup.string, 'validDomains', function pattern(message) {
  return this.test({
    message,
    test: (value) => {
      if (!value) {
        return true
      }
      return value
        .split(',')
        .every((domain) => domainRexExp.test(domain.trim()))
    },
  })
})

export const CompanySchema = (t) => {
  const invalidValue = t('common.invalidValue')
  const fieldRequired = t('common.fieldRequired')
  const domainsRequired = t('entities.company.domainsRequired')
  const invalidDomains = t('common.invalidDomains')
  const invalidUrl = t('common.invalidUrl')
  const invalidPercentage = t('common.invalidPercentage')

  return Yup.object().shape({
    code: Yup.string().required(fieldRequired),
    name: Yup.string().required(fieldRequired),
    givenRideCredits: Yup.number()
      .typeError(invalidValue)
      .integer(invalidValue)
      .required(fieldRequired),
    gotRideCredits: Yup.number()
      .typeError(invalidValue)
      .integer(invalidValue)
      .required(fieldRequired),
    bikeCredits: Yup.number()
      .typeError(invalidValue)
      .integer(invalidValue)
      .required(fieldRequired),
    walkCredits: Yup.number()
      .typeError(invalidValue)
      .integer(invalidValue)
      .required(fieldRequired),
    transitCredits: Yup.number()
      .typeError(invalidValue)
      .integer(invalidValue)
      .required(fieldRequired),
    smartWorkingCredits: Yup.number()
      .typeError(invalidValue)
      .integer(invalidValue)
      .required(fieldRequired),
    rideMatchMaxMinutes: Yup.number()
      .typeError(invalidValue)
      .integer(invalidValue),
    validDomains: Yup.string()
      .validDomains(invalidDomains)
      .when('identityProviderName', {
        is: (identityProviderName) => !!identityProviderName,
        then: Yup.string().required(domainsRequired),
      }),
    videoTutorialEnabled: Yup.boolean(),
    minimumValidDurationEnabled: Yup.boolean(),
    minimumValidDurationPercentage: Yup.number()
      .typeError(invalidValue)
      .integer(invalidValue)
      .min(0, invalidPercentage)
      .max(100, invalidPercentage)
      .when('minimumValidDurationEnabled', {
        is: (minimumValidDurationEnabled) => !!minimumValidDurationEnabled,
        then: Yup.number().required(fieldRequired),
      }),
    smartWorkingEnabled: Yup.boolean(),
    autoCheckOut: Yup.string().required(fieldRequired),
    showCarRideKmCost: Yup.boolean(),
    companyPrivacyUrl: Yup.string().matches(/http.*/, invalidUrl),
    customLinkUrl: Yup.string()
      .matches(/http.*/, invalidUrl)
      .when('customLinkLabel', {
        is: (customLinkLabel) => customLinkLabel?.length,
        then: Yup.string().required(fieldRequired),
      }),
    showHomeAddress: Yup.boolean(),
    maxDriverExtraTravel: Yup.number().nullable(),
  })
}
