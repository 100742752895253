import React from 'react'
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom'
import { Authenticator } from '@aws-amplify/ui-react'
import { I18n as AmplifyI18n } from 'aws-amplify'
import { translations as amplifyTranslations } from '@aws-amplify/ui'

import '@aws-amplify/ui-react/styles.css'

import { useTranslation } from 'react-i18next'
import AuthListener from './AuthListener'
import BackOfficeUserDetail from './components/pages/BackOfficeUserDetail'
import RewardingDetail from './components/pages/RewardingDetail'
import Rewardings from './components/pages/Rewardings'
import Rewards from './components/pages/Rewards'
import Redemptions from './components/pages/Redemptions'
import { AuthProvider } from './hooks/useAuth'
import { ConfigContextProvider } from './configProvider'

import ProtectedRoute from './components/routes/ProtectedRoute'
import UnauthorizedRoute from './components/routes/UnauthorizedRoute'

import AppBar from './components/organisms/AppBar'
import Spinner from './components/atoms/Spinner'
import Drawer from './components/atoms/Drawer'

import HomePage from './components/pages/HomePage'
import HeatMapPage from './components/pages/HeatMapPage'
import Companies from './components/pages/Companies'
import CompanyDetail from './components/pages/CompanyDetail'
import Users from './components/pages/Users'
import UserDetail from './components/pages/UserDetail'
import Rides from './components/pages/Rides'
import Headquarters from './components/pages/Headquarters'
import HeadquarterDetail from './components/pages/HeadquarterDetail'
import Sectors from './components/pages/Sectors'
import SectorDetail from './components/pages/SectorDetail'
import BackOfficeUsers from './components/pages/BackOfficeUsers'
import CompanyTechDetail from './components/pages/CompanyTechDetail'
import MessageDetail from './components/pages/MessageDetail'
import Messages from './components/pages/Messages'
import MessagingSettings from './components/pages/MessagingSettings'
import RideMatchAnalysisPage from './components/pages/RideMatchAnalysisPage'
import History from './components/pages/History'
import AuthHeader from './components/organisms/Auth/AuthHeader'
import AuthLoginHeader from './components/organisms/Auth/AuthLoginHeader'
import AuthLoginFooter from './components/organisms/Auth/AuthLoginFooter'
import { ConfirmDialogProvider } from './hooks/useConfirmDialog'
import SsoEmail from './components/pages/SsoEmail'
import RideDetail from './components/pages/RideDetail'
import Challenges from './components/pages/Challenges'
import ChallengeDetail from './components/pages/ChallengeDetail'
import TeamDetail from './components/pages/TeamDetail'

export default function MainRouter() {
  const { i18n } = useTranslation()

  AmplifyI18n.putVocabularies(amplifyTranslations)
  AmplifyI18n.setLanguage(i18n.language)

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/">
          <Redirect to="/homepage" />
        </Route>
        <Route path="/sso-email">
          <SsoEmail />
        </Route>
        <Route path="/auth-return-uri">
          <Redirect to="/homepage" push={false} />
        </Route>
        <Route>
          <AuthListener />
          <Authenticator
            hideSignUp
            components={{
              Header: AuthHeader,
              SignIn: {
                Header: AuthLoginHeader,
                Footer: AuthLoginFooter,
              },
            }}
          >
            {({ user }) => (
              <div style={{ display: 'flex', width: '100%' }}>
                <AuthProvider authData={user}>
                  <ConfigContextProvider
                    Loading={() => <Spinner show={true} />}
                    Error={({ error }) => (
                      <div>Error on loading config: {error}</div>
                    )}
                  >
                    <ConfirmDialogProvider>
                      <AppBar override={'Greetings'} />
                      <ProtectedRoute>
                        <Drawer />
                      </ProtectedRoute>
                      <ProtectedRoute exact path="/homepage">
                        <HomePage />
                      </ProtectedRoute>

                      <ProtectedRoute exact path="/heatMap">
                        <HeatMapPage />
                      </ProtectedRoute>

                      <ProtectedRoute exact path="/companies">
                        <Companies />
                      </ProtectedRoute>
                      <ProtectedRoute exact path="/company/:id">
                        <CompanyDetail />
                      </ProtectedRoute>
                      <ProtectedRoute exact path="/company">
                        <CompanyDetail />
                      </ProtectedRoute>

                      <ProtectedRoute
                        exact
                        path="/company/:companyId/headquarters"
                      >
                        <Headquarters />
                      </ProtectedRoute>

                      <ProtectedRoute
                        exact
                        path="/company/:companyId/headquarter/:id"
                      >
                        <HeadquarterDetail />
                      </ProtectedRoute>
                      <ProtectedRoute
                        exact
                        path="/company/:companyId/headquarter"
                      >
                        <HeadquarterDetail />
                      </ProtectedRoute>

                      <ProtectedRoute exact path="/company/:companyId/sectors">
                        <Sectors />
                      </ProtectedRoute>
                      <ProtectedRoute
                        exact
                        path="/company/:companyId/sector/:id"
                      >
                        <SectorDetail />
                      </ProtectedRoute>
                      <ProtectedRoute exact path="/company/:companyId/sector">
                        <SectorDetail />
                      </ProtectedRoute>

                      <ProtectedRoute
                        exact
                        path="/company/:companyId/rewardings"
                      >
                        <Rewardings />
                      </ProtectedRoute>
                      <ProtectedRoute
                        exact
                        path="/company/:companyId/rewarding/:companyRewardingTypeId"
                      >
                        <RewardingDetail />
                      </ProtectedRoute>
                      <ProtectedRoute
                        exact
                        path="/company/:companyId/rewarding"
                      >
                        <RewardingDetail />
                      </ProtectedRoute>

                      <ProtectedRoute
                        exact
                        path="/company/:companyId/challenges"
                      >
                        <Challenges />
                      </ProtectedRoute>

                      <ProtectedRoute
                        exact
                        path="/company/:companyId/challenges/:id"
                      >
                        <ChallengeDetail />
                      </ProtectedRoute>

                      <ProtectedRoute
                        exact
                        path="/company/:companyId/challenges/:challengeId/team"
                      >
                        <TeamDetail />
                      </ProtectedRoute>

                      <ProtectedRoute
                        exact
                        path="/company/:companyId/challenges/:challengeId/teams/:id"
                      >
                        <TeamDetail />
                      </ProtectedRoute>

                      <ProtectedRoute
                        exact
                        path="/company/:companyId/challenge"
                      >
                        <ChallengeDetail />
                      </ProtectedRoute>

                      <ProtectedRoute
                        exact
                        path="/company/:companyId/back-office-users"
                      >
                        <BackOfficeUsers />
                      </ProtectedRoute>
                      <ProtectedRoute
                        exact
                        path="/company/:companyId/back-office-users/:role"
                      >
                        <BackOfficeUsers />
                      </ProtectedRoute>
                      <ProtectedRoute
                        exact
                        path="/company/:companyId/back-office-user/:role"
                      >
                        <BackOfficeUserDetail />
                      </ProtectedRoute>
                      <ProtectedRoute
                        exact
                        path="/company/:companyId/back-office-user/:role/:userId"
                      >
                        <BackOfficeUserDetail />
                      </ProtectedRoute>

                      <ProtectedRoute exact path="/company/:companyId/history">
                        <History />
                      </ProtectedRoute>

                      <ProtectedRoute
                        exact
                        path="/company/:companyId/ride-match-analysis"
                      >
                        <RideMatchAnalysisPage />
                      </ProtectedRoute>

                      <ProtectedRoute
                        exact
                        path="/company/:companyId/tech-detail"
                      >
                        <CompanyTechDetail />
                      </ProtectedRoute>

                      <ProtectedRoute
                        exact
                        path="/company/:companyId/messaging-settings"
                      >
                        <MessagingSettings />
                      </ProtectedRoute>

                      <ProtectedRoute exact path="/users">
                        <Users />
                      </ProtectedRoute>
                      <ProtectedRoute exact path="/user/:id">
                        <UserDetail />
                      </ProtectedRoute>

                      <ProtectedRoute exact path="/messages">
                        <Messages />
                      </ProtectedRoute>
                      <ProtectedRoute exact path="/message/:id">
                        <MessageDetail />
                      </ProtectedRoute>
                      <ProtectedRoute exact path="/message">
                        <MessageDetail />
                      </ProtectedRoute>

                      <ProtectedRoute exact path="/redemptions">
                        <Redemptions />
                      </ProtectedRoute>

                      <ProtectedRoute exact path="/user/:userId/rides">
                        <Rides />
                      </ProtectedRoute>
                      <ProtectedRoute exact path="/user/:userId/rides/:rideId">
                        <RideDetail />
                      </ProtectedRoute>
                      <ProtectedRoute exact path="/user/:userId/Rewards">
                        <Rewards />
                      </ProtectedRoute>
                    </ConfirmDialogProvider>
                  </ConfigContextProvider>
                  <UnauthorizedRoute />
                </AuthProvider>
              </div>
            )}
          </Authenticator>
        </Route>
      </Switch>
    </BrowserRouter>
  )
}
