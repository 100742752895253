import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Checkbox as MuiCheckbox,
  Typography,
  Tooltip,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import T from 'prop-types'
import { Formik, Form } from 'formik'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import useFetchCompany from '../../../hooks/useFetchCompany'
import useFetchRentalProviders from '../../../hooks/useFetchRentalProviders'

import appbg from '../../../assets/images/app-background.png'
import config from '../../../config'
import request from '../../../lib/request'
import { reloadImageOnError } from '../../../lib/image-reloader'
import Select from '../../atoms/Select'
import TextField from '../../atoms/TextField'
import Checkbox from '../../atoms/Checkbox'
import Spinner from '../../atoms/Spinner'
import useSnackbar from '../Snackbar/useSnackbar'
import UploadDialog from '../UploadDialog'
import { useAuth } from '../../../hooks/useAuth'
import { useConfirmDialog } from '../../../hooks/useConfirmDialog'
import { CompanySchema } from './validators'

const LOGO_FIELD = 'logo'
const HP_IMAGE_FIELD = 'hpImage'

const imageRelativeFolder = 'uploads/logo/'

const useStyles = makeStyles((theme) => ({
  providers: {
    marginTop: '15px',
  },
}))

const appBackgroundMockupStyle = {
  width: '362px',
  maxHeight: '142px',
  position: 'relative',
  overflow: 'hidden',
}

const companyLogoStyle = {
  position: 'absolute',
  top: '0',
  width: '98%',
  textAlign: 'right',
}

export default function CompanyForm({ companyId }) {
  const classes = useStyles()
  const { showSnackbarMessage } = useSnackbar()
  const history = useHistory()
  const { t } = useTranslation()

  const { isAdmin } = useAuth()

  const { openConfirmDialog } = useConfirmDialog()

  const [uploadDialogField, setUploadDialogField] = useState(null)

  const isNew = !companyId
  const [pageMode, setPageMode] = useState(isNew ? 'edit' : 'view')
  const readOnly = pageMode === 'view'

  const {
    fetchedObject: company,
    isFetching: isFetchingCompany,
    fetchError: fetchCompanyError,
    refetch: refetchCompany,
  } = useFetchCompany(companyId)

  const {
    fetchedObject: rentalProviders,
    isFetching: isFetchingRentalProviders,
    fetchError: fetchRentalProvidersError,
  } = useFetchRentalProviders()

  const filteredRentalProviders = isAdmin
    ? rentalProviders
    : (rentalProviders || []).filter((rp) =>
        company?.rentalProviderIds?.includes(rp.id)
      )

  useEffect(() => {
    if (fetchCompanyError || fetchRentalProvidersError) {
      showSnackbarMessage({
        open: true,
        severity: 'error',
        message: `${t('common.unexpectedError')} ${
          fetchCompanyError || fetchRentalProvidersError
        }`,
      })
    }
  }, [fetchCompanyError, fetchRentalProvidersError, showSnackbarMessage, t])

  async function handleSubmit(values, actions) {
    try {
      const data = {
        code: values.code,
        name: values.name,
        givenRideCredits: values.givenRideCredits,
        gotRideCredits: values.gotRideCredits,
        bikeCredits: values.bikeCredits,
        walkCredits: values.walkCredits,
        transitCredits: values.transitCredits,
        busforfunCredits: values.busforfunCredits,
        smartWorkingCredits: values.smartWorkingCredits,
        rideMatchMaxMinutes: values.rideMatchMaxMinutes,
        validDomains: values.validDomains,
        videoTutorialEnabled: values.videoTutorialEnabled,
        minimumValidDurationEnabled: values.minimumValidDurationEnabled,
        minimumValidDurationPercentage: values.minimumValidDurationPercentage,
        carEnabled: values.carEnabled,
        bikeEnabled: values.bikeEnabled,
        walkEnabled: values.walkEnabled,
        transitEnabled: values.transitEnabled,
        busforfunEnabled: values.busforfunEnabled,
        smartWorkingEnabled: values.smartWorkingEnabled,
        rentalProviderIds: values.rentalProviderIds,
        autoCheckOut: values.autoCheckOut,
        showCarRideKmCost: values.showCarRideKmCost,
        companyPrivacyUrl: values.companyPrivacyUrl,
        customLinkLabel: values.customLinkLabel,
        customLinkUrl: values.customLinkUrl,
        showHomeAddress: values.showHomeAddress,
        statsPageMessage: values.statsPageMessage,
        maxDriverExtraTravel: values.maxDriverExtraTravel
          ? Number(values.maxDriverExtraTravel)
          : null,
      }

      if (isNew) {
        const { id } = await request.post(`/admin/companies`, data)
        actions.setSubmitting(false)
        history.replace(`/company/${id}`)
      } else {
        await request.patch(`/admin/companies/${companyId}`, data)
        actions.setSubmitting(false)
        setPageMode('view')
        Object.assign(company, data)
      }

      showSnackbarMessage({ open: true, severity: 'success' })
    } catch (e) {
      let message, autoHideDuration
      switch (e.response.data.errorCode) {
        case 'valid_domains_collision':
          message =
            t('entities.company.collidingDomains') +
            e.response.data.collidingCompanies
              .map((company) => company.name)
              .join(', ')
          autoHideDuration = 15000
          break
        case 'valid_domains_required':
          message = t('entities.company.domainsRequired')
          break
        default:
          message = e.response.data?.message || e.message
      }

      showSnackbarMessage({
        open: true,
        severity: 'error',
        autoHideDuration,
        message,
      })
      actions.setSubmitting(false)
    }
  }

  async function handleDelete(e) {
    e.preventDefault()
    try {
      await request.delete(`/admin/companies/${companyId}`)
      showSnackbarMessage({ open: true, severity: 'success' })
      history.replace(`/companies`)
    } catch (e) {
      showSnackbarMessage({
        open: true,
        severity: 'error',
        message: e.message,
      })
    }
  }

  const handleArchive = (e) => {
    openConfirmDialog({
      title: t(
        `companyDetailPage.archiveConfirmDialog.${
          company?.archivedAt ? 'removeArchive' : 'archive'
        }.title`
      ),
      description: t(
        `companyDetailPage.archiveConfirmDialog.${
          company?.archivedAt ? 'removeArchive' : 'archive'
        }.description`
      ),
      handleClose: null,
      handleConfirm: async () => {
        try {
          const result = await request.patch(
            `/admin/companies/${companyId}/archive`,
            {
              archive: !company?.archivedAt,
            }
          )

          Object.assign(company, { archivedAt: result?.archivedAt })
          showSnackbarMessage({ open: true, severity: 'success' })
        } catch (e) {
          showSnackbarMessage({
            open: true,
            severity: 'error',
            message: e.message,
          })
        }
      },
    })
  }

  function handleEdit(e) {
    e.preventDefault()
    setPageMode('edit')
  }

  function handleReset() {
    setPageMode('view')
  }

  const saveImage = async (file) => {
    if (!uploadDialogField) {
      console.error('Error: uploadDialogField is null!') // eslint-disable-line
      showSnackbarMessage({
        open: true,
        severity: 'error',
        message: `${t('common.unexpectedError')}`,
      })
      return
    }
    await request.patch(`/admin/companies/${companyId}`, {
      [uploadDialogField]: `/${imageRelativeFolder}${file.newFileName}`,
    })
    setUploadDialogField(null)
    return refetchCompany()
  }

  const deleteImage = async (field) => {
    await request.patch(`/admin/companies/${companyId}`, {
      [field]: '',
    })
    return refetchCompany()
  }

  const reloadHpImageOnError = reloadImageOnError()
  const reloadLogoOnError = reloadImageOnError()

  const { hiddenFields } = company || {}

  return isFetchingCompany ? (
    <Spinner show={true} />
  ) : (
    <Box maxWidth={900} ml={0}>
      {!isNew && !!company?.archivedAt && (
        <Typography variant="h6" color="error">
          {t('companyDetailPage.archived').toUpperCase()}
        </Typography>
      )}

      <Formik
        validationSchema={CompanySchema(t)}
        initialValues={{
          code: company?.code || '',
          name: company?.name || '',
          givenRideCredits: company?.givenRideCredits || 0,
          gotRideCredits: company?.gotRideCredits || 0,
          bikeCredits: company?.bikeCredits ?? company?.givenRideCredits ?? 0,
          walkCredits: company?.walkCredits ?? company?.givenRideCredits ?? 0,
          transitCredits:
            company?.transitCredits ?? company?.givenRideCredits ?? 0,
          busforfunCredits:
            company?.busforfunCredits ?? company?.givenRideCredits ?? 0,
          smartWorkingCredits:
            company?.smartWorkingCredits ??
            (company?.givenRideCredits ? company.givenRideCredits * 2 : 0),
          rideMatchMaxMinutes: company?.rideMatchMaxMinutes || '',
          validDomains: company?.validDomains || '',
          videoTutorialEnabled: company?.videoTutorialEnabled || false,
          minimumValidDurationEnabled:
            company?.minimumValidDurationEnabled || false,
          minimumValidDurationPercentage:
            company?.minimumValidDurationPercentage || '',
          carEnabled: company?.carEnabled || false,
          bikeEnabled: company?.bikeEnabled || false,
          walkEnabled: company?.walkEnabled || false,
          transitEnabled: company?.transitEnabled || false,
          busforfunEnabled: company?.busforfunEnabled || false,
          smartWorkingEnabled: company?.smartWorkingEnabled || false,
          rentalProviderIds: company?.rentalProviderIds || [],
          autoCheckOut: company?.autoCheckOut || 'never',
          showCarRideKmCost: company?.showCarRideKmCost || false,
          companyPrivacyUrl: company?.companyPrivacyUrl || '',
          customLinkLabel: company?.customLinkLabel || '',
          customLinkUrl: company?.customLinkUrl || '',
          showHomeAddress: company?.showHomeAddress || false,
          statsPageMessage: company?.statsPageMessage || '',
          maxDriverExtraTravel: company?.maxDriverExtraTravel || '',
        }}
        onSubmit={handleSubmit}
        onReset={handleReset}
        enableReinitialize={true}
      >
        {({ isValid, dirty, isSubmitting, values, handleChange }) => (
          <Form>
            <Box>
              <TextField
                margin="dense"
                style={{ width: '20%' }}
                required
                inputProps={{
                  autoComplete: 'off',
                  readOnly,
                }}
                label={t('entities.company.code')}
                name="code"
                size="small"
              />
              <TextField
                margin="dense"
                style={{ width: '80%' }}
                required
                inputProps={{
                  autoComplete: 'off',
                  readOnly,
                }}
                label={t('entities.company.name')}
                name="name"
                size="small"
              />
            </Box>

            <Box>
              <div>
                <label
                  style={{
                    verticalAlign: 'bottom',
                    margin: 'auto 16px auto auto',
                  }}
                >
                  {t('companyDetailPage.logo')}:
                </label>
              </div>
              {company?.logo && (
                <>
                  <div style={appBackgroundMockupStyle}>
                    <img src={appbg} width="360" alt="" />
                    <div style={companyLogoStyle}>
                      <img
                        src={`${config.upload.baseUrl}${company.logo}`}
                        alt="logo"
                        style={{ maxHeight: '50px', maxWidth: '280px' }}
                        onError={reloadLogoOnError}
                      />
                    </div>
                  </div>
                  {isAdmin && readOnly && (
                    <Button
                      onClick={() => deleteImage(LOGO_FIELD)}
                      color="primary"
                      aria-label={t('common.deleteImage')}
                    >
                      {t('common.deleteImage')}
                    </Button>
                  )}
                </>
              )}
              {isAdmin && readOnly && (
                <Button
                  onClick={() => setUploadDialogField(LOGO_FIELD)}
                  color="primary"
                  aria-label={t('common.chooseImage')}
                >
                  {t('common.chooseImage')}
                </Button>
              )}
            </Box>

            <Box>
              <div>
                <label
                  style={{
                    verticalAlign: 'bottom',
                    margin: 'auto 16px auto auto',
                  }}
                >
                  {t('companyDetailPage.hpImage')}:
                </label>
              </div>
              {company?.hpImage && (
                <>
                  <div>
                    <img
                      src={`${config.upload.baseUrl}${company.hpImage}`}
                      alt="hpImage"
                      style={{ maxHeight: '225px', maxWidth: '300px' }}
                      onError={reloadHpImageOnError}
                    />
                  </div>
                  {isAdmin && readOnly && (
                    <Button
                      onClick={() => deleteImage(HP_IMAGE_FIELD)}
                      color="primary"
                      aria-label={t('common.deleteImage')}
                    >
                      {t('common.deleteImage')}
                    </Button>
                  )}
                </>
              )}
              {isAdmin && readOnly && (
                <Button
                  onClick={() => setUploadDialogField(HP_IMAGE_FIELD)}
                  color="primary"
                  aria-label={t('common.chooseImage')}
                >
                  {t('common.chooseImage')}
                </Button>
              )}
            </Box>

            <TextField
              fullWidth
              margin="dense"
              inputProps={{
                autoComplete: 'off',
                readOnly,
              }}
              label={t('entities.company.validDomains')}
              name="validDomains"
              size="small"
            />
            <Box sx={{ whiteSpace: 'nowrap' }}>
              <Checkbox
                inputProps={{
                  disabled: readOnly,
                }}
                color="primary"
                label={t('entities.company.carEnabled')}
                name="carEnabled"
              />

              <TextField
                margin="dense"
                inputProps={{
                  autoComplete: 'off',
                  readOnly,
                }}
                required
                label={t('entities.company.givenRideCredits')}
                name="givenRideCredits"
                size="small"
              />

              <TextField
                margin="dense"
                inputProps={{
                  autoComplete: 'off',
                  readOnly,
                }}
                required
                label={t('entities.company.gotRideCredits')}
                name="gotRideCredits"
                size="small"
              />

              <TextField
                margin="dense"
                inputProps={{
                  autoComplete: 'off',
                  readOnly,
                }}
                label={t('entities.company.rideMatchMaxMinutes')}
                name="rideMatchMaxMinutes"
                size="small"
              />
              <Tooltip
                title={t('entities.company.maxDriverExtraTravel', {
                  param:
                    company?.globalFields
                      ?.maxExtraTravelMinutesToPickUpPassenger,
                })}
              >
                <span>
                  <TextField
                    type="number"
                    margin="dense"
                    inputProps={{
                      autoComplete: 'off',
                      readOnly,
                    }}
                    size="small"
                    label={t('entities.company.maxDriverExtraTravelLabel')}
                    name="maxDriverExtraTravel"
                  />
                </span>
              </Tooltip>
            </Box>
            <Box>
              <Checkbox
                inputProps={{
                  disabled: readOnly,
                }}
                color="primary"
                label={t('entities.company.bikeEnabled')}
                name="bikeEnabled"
              />

              <TextField
                margin="dense"
                inputProps={{
                  autoComplete: 'off',
                  readOnly,
                }}
                required
                label={t('entities.company.bikeCredits')}
                name="bikeCredits"
                size="small"
              />
            </Box>
            <Box>
              <Checkbox
                inputProps={{
                  disabled: readOnly,
                }}
                color="primary"
                label={t('entities.company.walkEnabled')}
                name="walkEnabled"
              />

              <TextField
                margin="dense"
                inputProps={{
                  autoComplete: 'off',
                  readOnly,
                }}
                required
                label={t('entities.company.walkCredits')}
                name="walkCredits"
                size="small"
              />
            </Box>
            <Box>
              <Checkbox
                inputProps={{
                  disabled: readOnly,
                }}
                color="primary"
                label={t('entities.company.transitEnabled')}
                name="transitEnabled"
              />

              <TextField
                margin="dense"
                inputProps={{
                  autoComplete: 'off',
                  readOnly,
                }}
                required
                label={t('entities.company.transitCredits')}
                name="transitCredits"
                size="small"
              />
            </Box>
            {!hiddenFields?.includes('busforfun') && !isNew && (
              <Box>
                <Checkbox
                  inputProps={{
                    disabled: readOnly,
                  }}
                  color="primary"
                  label={t('entities.company.busforfunEnabled')}
                  name="busforfunEnabled"
                />

                <TextField
                  margin="dense"
                  inputProps={{
                    autoComplete: 'off',
                    readOnly: readOnly,
                  }}
                  required
                  label={t('entities.company.busforfunCredits')}
                  name="busforfunCredits"
                  size="small"
                />
              </Box>
            )}
            <Box>
              <Checkbox
                inputProps={{
                  disabled:
                    readOnly ||
                    hiddenFields?.includes('zucchetti_smartworking'),
                }}
                disabled={hiddenFields?.includes('zucchetti_smartworking')}
                color="primary"
                label={t('entities.company.smartWorkingEnabled')}
                name="smartWorkingEnabled"
              />

              {hiddenFields?.includes('zucchetti_smartworking') && (
                <Checkbox
                  inputProps={{
                    disabled: hiddenFields?.includes('zucchetti_smartworking'),
                  }}
                  color="primary"
                  label={t('entities.company.zucchettiSmartWorkingEnabled')}
                />
              )}

              <TextField
                margin="dense"
                inputProps={{
                  autoComplete: 'off',
                  readOnly,
                }}
                required
                label={t('entities.company.smartWorkingCredits')}
                name="smartWorkingCredits"
                size="small"
              />
            </Box>
            <Box style={{ margin: '0 0 16px 0' }}>
              <label
                style={{
                  verticalAlign: 'bottom',
                  margin: 'auto 16px auto auto',
                }}
              >
                Auto-CheckOut:
              </label>
              <Select name="autoCheckOut" disabled={readOnly}>
                <option value={'never'}>Mai</option>
                <option value={'startedSessions'}>
                  Per viaggi iniziati, ma non conclusi
                </option>
                <option value={'all'}>
                  Per tutti i viaggi, inclusi quelli non iniziati
                </option>
              </Select>
            </Box>
            <Box>
              <fieldset>
                <legend>
                  {t('companyDetailPage.minimumValidDurationFrameTitle')}
                </legend>
                <Checkbox
                  inputProps={{
                    disabled: readOnly,
                  }}
                  color="primary"
                  label={t('entities.company.minimumValidDurationEnabled')}
                  name="minimumValidDurationEnabled"
                />
                <TextField
                  margin="dense"
                  inputProps={{
                    autoComplete: 'off',
                    readOnly,
                    style: { width: '400px' },
                  }}
                  size="small"
                  label={t('entities.company.minimumValidDurationPercentage')}
                  name="minimumValidDurationPercentage"
                />
              </fieldset>
            </Box>
            <Box>
              <Checkbox
                inputProps={{
                  disabled: readOnly,
                }}
                color="primary"
                label={t('entities.company.videoTutorialEnabled')}
                name="videoTutorialEnabled"
              />

              <Checkbox
                inputProps={{
                  disabled: readOnly,
                }}
                color="primary"
                label={t('entities.company.showCarRideKmCost')}
                name="showCarRideKmCost"
              />
            </Box>
            {!isFetchingRentalProviders && filteredRentalProviders.length && (
              <Box>
                <FormControl component="fieldset" className={classes.providers}>
                  <FormLabel component="legend">
                    {t('entities.company.enabledRentalProviders')}
                  </FormLabel>
                  <FormGroup>
                    {filteredRentalProviders.map((rentalProvider) => (
                      <FormControlLabel
                        key={rentalProvider.id}
                        control={
                          <MuiCheckbox
                            name="rentalProviderIds"
                            value={rentalProvider.id}
                            checked={values.rentalProviderIds.includes(
                              rentalProvider.id
                            )}
                            onChange={handleChange}
                            color="primary"
                            inputProps={{
                              disabled: readOnly,
                            }}
                          />
                        }
                        label={rentalProvider.name}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              </Box>
            )}
            <Box>
              <TextField
                style={{ width: '80%' }}
                margin="dense"
                inputProps={{
                  autoComplete: 'off',
                  readOnly,
                }}
                label={t('entities.company.companyPrivacyUrl')}
                name="companyPrivacyUrl"
                size="small"
              />
            </Box>
            <Box>
              <TextField
                style={{ width: '40%' }}
                margin="dense"
                inputProps={{
                  autoComplete: 'off',
                  readOnly,
                }}
                size="small"
                label={t('entities.company.customLinkLabel')}
                name="customLinkLabel"
              />
              <TextField
                style={{ width: '60%' }}
                margin="dense"
                inputProps={{
                  autoComplete: 'off',
                  readOnly: readOnly || !values.customLinkLabel?.length,
                }}
                size="small"
                label={t('entities.company.customLinkUrl')}
                name="customLinkUrl"
              />
            </Box>
            <Box>
              <Checkbox
                inputProps={{
                  disabled: readOnly,
                }}
                color="primary"
                label={t('entities.company.showHomeAddress')}
                name="showHomeAddress"
              />
            </Box>
            <Box>
              <TextField
                style={{ width: '100%' }}
                margin="dense"
                inputProps={{
                  autoComplete: 'off',
                  readOnly,
                }}
                label={t('entities.company.statsPageMessage')}
                name="statsPageMessage"
                size="small"
                multiline
              />
            </Box>
            <Container>
              {readOnly ? (
                <>
                  <Button
                    onClick={() => history.goBack()}
                    color="secondary"
                    aria-label={t('common.back')}
                  >
                    {t('common.back')}
                  </Button>
                  {isAdmin && (
                    <>
                      <Button
                        onClick={handleEdit}
                        color="primary"
                        aria-label={t('common.edit')}
                      >
                        {t('common.edit')}
                      </Button>
                      <Button
                        onClick={handleDelete}
                        color="primary"
                        aria-label={t('common.delete')}
                        disabled
                      >
                        {t('common.delete')}
                      </Button>
                      <Button
                        onClick={handleArchive}
                        color="primary"
                        aria-label={t(
                          `common.${
                            company?.archivedAt ? 'removeArchive' : 'archive'
                          }`
                        )}
                      >
                        {t(
                          `common.${
                            company?.archivedAt ? 'removeArchive' : 'archive'
                          }`
                        )}
                      </Button>
                    </>
                  )}
                </>
              ) : (
                <>
                  {isNew ? (
                    <Button
                      onClick={() => history.goBack()}
                      color="secondary"
                      aria-label={t('common.back')}
                    >
                      {t('common.back')}
                    </Button>
                  ) : (
                    <Button
                      type="reset"
                      color="secondary"
                      aria-label={t('common.cancel')}
                    >
                      {t('common.cancel')}
                    </Button>
                  )}
                  <Button
                    type="submit"
                    disabled={!isValid || !dirty || isSubmitting}
                    color="primary"
                  >
                    {t('common.save')}
                  </Button>
                </>
              )}
            </Container>
          </Form>
        )}
      </Formik>
      <UploadDialog
        options={{
          open: !!uploadDialogField,
          handleClose: () => setUploadDialogField(false),
          handleSave: saveImage,
          relativePath: imageRelativeFolder,
        }}
      />
    </Box>
  )
}

CompanyForm.propTypes = {
  companyId: T.string,
}
