import * as Yup from 'yup'

export const RewardingTransactionSchema = (t) => {
  const invalidValue = t('common.invalidValue')
  const fieldRequired = t('common.fieldRequired')

  return Yup.object().shape({
    depositAmount: Yup.number().typeError(invalidValue).required(fieldRequired),
    depositDescription: Yup.string().trim().required(fieldRequired),
  })
}
