import { useCallback } from 'react'
import request from '../lib/request'
import useFetch from './useFetch'

export default function useFetchRewardingTransactionsSummary(
  companyRewardingTypeId
) {
  const getTransactionsSummary = useCallback(async () => {
    return request.get(
      `/admin/rewarding/company/${companyRewardingTypeId}/transactions/summary`
    )
  }, [companyRewardingTypeId])

  return useFetch(getTransactionsSummary)
}
