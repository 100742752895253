import React from 'react'
import T from 'prop-types'
import FilterBar from '../FilterBar/FilterBar'

export default function RewardingFilterBar({ onAddClick }) {
  return (
    <FilterBar entity={'rewarding'} controls={null} onAddClick={onAddClick} />
  )
}

RewardingFilterBar.propTypes = {
  onAddClick: T.func.isRequired,
}
