import React, { useState, useRef, useCallback, useEffect } from 'react'

import { Card } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../../../hooks/useAuth'
import useCompaniesSectors from '../../../hooks/useCompaniesSectors'
import request from '../../../lib/request'
import Table from '../../Table'
import Action from '../../atoms/Action'
import useSnackbar from '../Snackbar/useSnackbar'
import MessageFilterBar from './MessageFilterBar'

const fetchMessages = async (query, filters) => {
  const search = filters.search || ''
  const companyIds = filters.companyIds || ''
  const orderCollection = query.orderByCollection.find((c) => c.sortOrder === 1)
  const orderBy = orderCollection?.orderByField || ''
  const orderDirection = orderCollection?.orderDirection || ''
  const page = query.page ? query.page + 1 : ''
  const pageSize = query.pageSize || ''

  const { items: data, total: totalCount } = await request.get(
    `/admin/messages?search=${search}&companyIds=${companyIds}&orderBy=${orderBy}&orderDirection=${orderDirection}&page=${page}&pageSize=${pageSize}`
  )
  const offset = query.pageSize * query.page

  return {
    data,
    page: Math.ceil(offset / query.pageSize),
    totalCount,
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 0),
    flex: 1,
  },
}))

export default function MessageTable() {
  const history = useHistory()
  const { isAdmin } = useAuth()
  const companiesSectors = useCompaniesSectors()
  const [filters, setFilters] = useState({
    search: '',
    companyIds: companiesSectors.length === 1 ? [companiesSectors[0].id] : [],
    archived: false,
  })
  const tableRef = useRef()
  const { showSnackbarMessage } = useSnackbar()
  const { t } = useTranslation()
  const classes = useStyles()

  const columns = [
    { title: t('messagesPage.tableHeaders.channel'), field: 'channel' },
    { title: t('messagesPage.tableHeaders.title'), field: 'title' },
    {
      title: t('messagesPage.tableHeaders.creationDate'),
      field: 'creationDate',
      render: (item) => new Date(item.creationDate).toLocaleString(),
    },
    {
      title: t('messagesPage.tableHeaders.frequency'),
      field: 'frequency',
      render: (item) =>
        t(
          `entities.message.frequencies.${
            item.details?.scheduling?.frequency || 'ON_DEMAND'
          }`
        ),
    },
    {
      title: t('messagesPage.tableHeaders.startDate'),
      field: 'startDate',
      render: (item) =>
        item.details.scheduling?.startDate
          ? new Date(item.details.scheduling.startDate).toLocaleString()
          : '',
    },
    {
      title: t('messagesPage.tableHeaders.endDate'),
      field: 'endDate',
      render: (item) =>
        item.details.scheduling?.endDate
          ? new Date(item.details.scheduling.endDate).toLocaleString()
          : '',
    },
    {
      title: t('messagesPage.tableHeaders.sentDate'),
      field: 'sentDate',
      render: (item) =>
        item.details.sentDate
          ? new Date(item.details.sentDate).toLocaleString()
          : '',
    },
  ]
  if (isAdmin) {
    columns.unshift({
      title: t('messagesPage.tableHeaders.targetCompanyNames'),
      field: 'targetCompanyNames',
      sorting: false,
      render: (item) => item.targets.map((t) => t.companyName).join(', '),
    })
  }

  useEffect(() => {
    tableRef.current.onQueryChange()
  }, [filters])

  const memoedFetch = useCallback(
    async (query) => {
      return fetchMessages(query, filters).catch((e) => {
        showSnackbarMessage({
          open: true,
          severity: 'error',
          message: `${t('common.unexpectedError')} ${e.message}`,
        })
        throw new Error('')
      })
    },
    [filters, showSnackbarMessage, t]
  )

  const onAddClick = () => history.push(`/message`)

  return (
    <Card className={classes.root}>
      <MessageFilterBar
        filters={filters}
        setFilters={setFilters}
        companiesSectors={companiesSectors}
        onAddClick={onAddClick}
      />
      <Table
        style={{ boxShadow: 'none' }}
        tableRef={tableRef}
        columns={columns}
        components={{
          Action,
        }}
        title=""
        data={memoedFetch}
        actions={[
          {
            icon: '',
            text: t('common.detail'),
            onClick: (e, rowData) => {
              history.push(`/message/${rowData.id}`)
            },
          },
        ]}
      />
    </Card>
  )
}
