import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Toolbar, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import useFetchCompany from '../../hooks/useFetchCompany'
import Spinner from '../atoms/Spinner'
import RewardingForm from '../organisms/RewardingForm'
import useSnackbar from '../organisms/Snackbar/useSnackbar'

export default function RewardingDetail() {
  const { t } = useTranslation()
  const { showSnackbarMessage } = useSnackbar()

  const { companyId, companyRewardingTypeId } = useParams()
  const {
    fetchedObject: company,
    isFetching,
    fetchError,
  } = useFetchCompany(companyId)

  useEffect(() => {
    if (fetchError) {
      showSnackbarMessage({
        open: true,
        severity: 'error',
        message: `${t('common.unexpectedError')} ${fetchError}`,
      })
    }
  }, [fetchError, showSnackbarMessage, t])

  return isFetching || !company ? (
    <Spinner show={true} />
  ) : (
    <div className="content-container">
      <Toolbar />
      <Typography variant="h3">{t('rewardingPage.title')}</Typography>
      <Typography variant="h4">{company.name}</Typography>
      <RewardingForm
        companyId={companyId}
        companyRewardingTypeId={companyRewardingTypeId}
      />
    </div>
  )
}
