import { useCallback } from 'react'
import request from '../lib/request'
import useFetch from './useFetch'

export default function useFetchMessage(messageId) {
  const fetchCompany = useCallback(async () => {
    if (!messageId) {
      return null
    }
    const message = await request.get(`/admin/messages/${messageId}`)

    if (message?.details?.scheduling?.startDate) {
      message.details.scheduling.startDate = new Date(
        message.details.scheduling.startDate
      )
    }

    if (message?.details?.scheduling?.endDate) {
      message.details.scheduling.endDate = new Date(
        message.details.scheduling.endDate
      )
    }
    return message
  }, [messageId])

  return useFetch(fetchCompany)
}
