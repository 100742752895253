import * as Yup from 'yup'

import { rewardingTypeIds } from '../../../../hooks/useFetchRewardingTypes'
import { emailsCSV } from '../../../../lib/validation-helper'

Yup.addMethod(Yup.string, 'validRedemptionAmounts', function pattern(message) {
  return this.test({
    message,
    test: (value) => {
      if (!value) {
        return true
      }
      return value.split(',').every((amount) => parseFloat(amount, 10) > 0)
    },
  })
})

export const RewardingSchema = (t) => {
  const invalidValue = t('common.invalidValue')
  const fieldRequired = t('common.fieldRequired')
  const invalidRedemptionAmounts = t('common.invalidRedemptionAmounts')

  return Yup.object().shape({
    rewardingTypeId: Yup.string().required(fieldRequired),
    creditValueInEuro: Yup.number()
      .typeError(invalidValue)
      .when('rewardingTypeId', {
        is: (rewardingTypeId) =>
          rewardingTypeId === rewardingTypeIds.VOUCHER ||
          rewardingTypeId === rewardingTypeIds.EXTERNAL_WITH_REDEMPTION,
        then: Yup.number().required(fieldRequired),
      }),
    redemptionAmounts: Yup.string()
      .validRedemptionAmounts(invalidRedemptionAmounts)
      .when('rewardingTypeId', {
        is: (rewardingTypeId) =>
          rewardingTypeId === rewardingTypeIds.VOUCHER ||
          rewardingTypeId === rewardingTypeIds.EXTERNAL_WITH_REDEMPTION,
        then: Yup.string().required(fieldRequired),
      }),
    maxCreditsPerDay: Yup.number()
      .typeError(invalidValue)
      .integer(invalidValue),
    maxCreditsPerWeek: Yup.number()
      .typeError(invalidValue)
      .integer(invalidValue),
    maxCreditsPerMonth: Yup.number()
      .typeError(invalidValue)
      .integer(invalidValue),
    companyEmails: emailsCSV(t),
  })
}
