import { useCallback } from 'react'
import request from '../lib/request'
import useFetch from './useFetch'

export const rewardingTypeIds = {
  GREENAPES: 'GREENAPES',
  EXTERNAL_WITH_CREDITS: 'EXTERNAL_WITH_CREDITS',
  EXTERNAL_WITHOUT_CREDITS: 'EXTERNAL_WITHOUT_CREDITS',
  EXTERNAL_WITH_REDEMPTION: 'EXTERNAL_WITH_REDEMPTION',
  VOUCHER: 'VOUCHER',
}

export default function useFetchRewardingTypes() {
  const rewardingTypes = useCallback(async () => {
    return request.get('/admin/rewarding/types')
  }, [])

  return useFetch(rewardingTypes)
}
